/* material ui over rights */
@import url("MuiGridV4.css");

body.loader-active {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

div.axisLabels.xaxisLabel,
div.axisLabels.yaxisLabel {
  font-weight: 400 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

td.legendLabel {
  line-height: 1.1;
}

.active-breadcrumb-node p:last-child {
  font-weight: 600 !important;
}

td.legendLabel {
  border-bottom: dashed 1px #428bca;
  color: #337ab7;
}

td.legendLabel:hover {
  border-bottom: dashed 1px #23527c;
  cursor: pointer;
  color: #23527c;
}

#error-dialog-description a,
#rtc-error-line a,
#notification-snackbar a {
  color: #53b6ef;
  text-decoration: none;
  cursor: pointer;
}

.highcharts-credits {
  visibility: hidden !important;
}

.ag-react-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ag-react-container .MuiTimelineDot-root {
  margin: auto !important;
}

.ag-theme-material .ag-tabs {
  min-width: 270px !important;
}
.ag-theme-material .ag-ltr .ag-label-align-right .ag-label {
  overflow-x: auto !important;
}
.ag-virtual-list-container {
  overflow: unset !important;
}
/* remove comments if needed #5441 */
.svg_icn_primary {
  /*fill: #61a93f;*/
}

.svg_icn_secondary {
  /*fill: #b44269;*/
}

.before {
  page-break-before: always;
}
.after {
  page-break-after: always;
}
.avoid {
  page-break-inside: avoid;
}
#avoid {
  page-break-inside: avoid;
}

.custom-menu {
  display: none;
  z-index: 1000;
  position: absolute;
  overflow: hidden;
  border: 1px solid #ccc;
  white-space: nowrap;
  font-family: sans-serif;
  background: #fff;
  color: #333;
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
}

.custom-menu li {
  padding: 8px 12px;
  cursor: pointer;
  list-style-type: none;
}

.custom-menu li:hover {
  background-color: #def;
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}

.ag-theme-material .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-material .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-material .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
.ag-theme-material .ag-ltr .ag-cell-range-single-cell,
.ag-theme-material .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-material .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-material .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-material .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
.ag-theme-material .ag-rtl .ag-cell-range-single-cell,
.ag-theme-material .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

.ag-header-row {
  background: #eaeaea;
}

.css-o1u9ud-PrivateHiddenCss-root {
  background: #273238;
}

#userMedia-table {
  display: block;
}

.ag-pinned-right-header {
  border-left: none !important;
}
